<template>
  <div class="submission-discussion">
    <v-btn
      small
      block
      outlined
      color="primary"
      class="non-focused"
      @click="replySheet.visible = true"
      >Reply</v-btn
    >
    <h4 class="mt-4">Replies</h4>

    <intract-smart-list
        class="mt-2"
        ref="board-post-replies-smart-list"
        :endpoint="`${ Helper.addUrlParams(endpoints.boardPostReplyViewSet,'post_id=' + postId )}`"
        noMoreDataText="Test"
        paginated
        disableSearch
        >
        <template v-slot:list-item="{ item: reply }">
            <v-list-item>
                <v-list-item-content>
                <v-list-item-subtitle class="primary--text"
                    >{{ reply.user.full_name }}</v-list-item-subtitle
                >
                <v-list-item-title class="text-wrap">{{ reply.reply }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                    {{ moment(reply.created).format('lll') }}
                </v-list-item-subtitle>
                </v-list-item-content>
                <intract-drop-down-menu
                v-if="reply.user.id == currentUser.id"
                :options-list="getDropdownOptions(reply)"
                />
            </v-list-item>
        </template>
    </intract-smart-list>

    <!-- <p class="mb-2">Be the first one to reply</p> -->
    
    
    <intract-create-edit-sheet
    persistent
      key="submission-discussion-reply-sheet"
      title="Write a Message"
      :visible="replySheet.visible"
      @close="replySheet.visible = false"
      :fields="formFields"
      :endpoint="endpoints.boardPostReplyViewSet"
      :data-object="replySheet.obj"
      :create-success-message="replySheet.createSuccessMessage"
      @objectCreated="replyCreated"
      @updateObject="(obj) => (replySheet.obj = obj)"
    />
    <confirmation-dialog
      title="Are you sure you want to delete this reply?"
      description="This action cannot be undone"
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteReply"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
import moment from 'moment';
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';
import EventBus from "@utils/event_bus";

export default {
  name: "BoardPostReplies",
  mixins: [Mixins.essentials,Mixins.pullToRefresh],
  
  props: {
    postId: {
      type: Number,
      default: null,
    },
  },
  components: {
    IntractCreateEditSheet,
    IntractDropDownMenu,
    ConfirmationDialog,
    IntractSmartList,

  },

  data() {
    return {
      replies: [],
      moment: moment,
      toBeDeletedReply: null,
      showDeleteDialog :false,
      replySheet: {
        visible: false,
        createSuccessMessage: "You have replied to this thread.",
        editId: null,
        obj: {
          post: null,
          user: null,
        },
      },
    };
  },
  computed: {
    formFields() {
      return {
        reply: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Reply",
          required: true,
          md: 12,
          max: 255,
        },
      };
    },
  },
  methods: {
    getDropdownOptions(reply) {
      var self = this;
      return [
        {
          obj: reply,
          value: 1,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedReply = reply;
            self.showDeleteDialog = true;
          }
        },
      ];
    },

    async replyCreated() {
        await this.onRefresh();
    },

    async getAllReplies(state) {
    if(!state){
        // refresh
        this.pagination.nextUrl = this.pagination.count = null;
        this.replies = [];
        this.firstLoad = true;

        this.infiniteIdentifier = new Date().toString();
        return;
    }

        var url = this.firstLoad
        ? this.Helper.addUrlParams(this.endpoints.boardPostReplyViewSet,`post_id=${this.postId}`)
        : this.pagination.nextUrl;
        var response = await this.api.call(this.essentials, url);
        if (response) {
            this.firstLoad = false;
            this.replies.push(...response.results);
            this.pagination.nextUrl = response.next;
            this.pagination.count = response.count;
            if (state) {
                if (this.replies.length == response.count) state.complete();
                else state.loaded();
            }
        }
    },

    async deleteReply() {
      var url = this.endpoints.boardPostReplyViewSet + this.toBeDeletedReply.id + "/";
      this.toBeDeletedReply = null;
      this.showDeleteDialog = false;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        await this.onRefresh();
        this.showSnackbar({
          title: "Reply has been successfully deleted!",
          type: "success",
        });
      }
    },

    async setListeners() {
      EventBus.$on(
        "board_post_replies__reply_deleted",
        (id) => (this.replies = this.replies.filter((r) => r.id != id))
      );

      EventBus.$on("board_post_replies__reply_updated", (reply) => {
        var listReply = this.replies.find((r) => r.id == reply.id);
        if (listReply) {
          listReply.reply = reply.reply
          listReply.created = reply.created
        }
      });
    },

    async onRefresh() {
        if(this.$refs['board-post-replies-smart-list'])
            this.$refs['board-post-replies-smart-list'].getItems();
    },
  },
  created() {
    this.replySheet.obj.user = this.currentUser.id;
    this.replySheet.obj.post = this.postId;
    this.setListeners();
    // this.getAllReplies();
  },
};
</script>